<template>
  <v-main align="center">
    <v-container fluid>
      <v-card class="elevation-12">
        <v-card-text>
          <v-row>
            <!-- Create Log data table -->
            <v-col class="pb-0 mb-0" cols="12" :sm="12" :md="12">
              <v-card v-if="currentUser.userId == 1" class="pb-0 mb-0">
                <v-card-title>Admin Controls</v-card-title>
                <v-btn class="ma-4 pa-4" @click="dialog = true"
                  >Seed Nordpool
                </v-btn>
                <v-card-text class="mt-4 pt-4 text-subtitle-1"
                  >{{ response }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="12" :sm="12" :md="12">
              <LogTable />
            </v-col>
          </v-row>

          <v-row>
            <feature-access-list />
          </v-row>
        </v-card-text>

        <v-dialog v-model="dialog" max-width="600">
          <v-card>
            <v-card-title>
              <span>Pick Date</span>
            </v-card-title>
            <v-date-picker v-model="targetDate"></v-date-picker>

            <v-card-actions>
              <v-btn @click="seedNordpool" color="primary" type="submit" x-large
                >{{ $t("common.save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import LogTable from "@/components/common/LogTable";
import { mapState } from "vuex";
import IntegrationsRepository from "../api/repositories/integrationsRepository";
import FeatureAccessList from "@/components/featureAccess/FeatureAccessList.vue";

export default {
  name: "AdminDashboard",
  data() {
    return {
      response: "Awaiting input...",
      dialog: false,
      targetDate: null,
    };
  },

  computed: {
    ...mapState("users", ["currentUser"]),
  },

  methods: {
    async seedNordpool() {
      let date = new Date(this.targetDate);
      let res = await IntegrationsRepository.seedNordpool(
        this.currentUser,
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate()
      );
      this.response = res.status + " " + res.statusText;

      this.dialog = false;
    },
  },

  components: {
    FeatureAccessList,
    LogTable,
  },
};
</script>
<style scoped>
.fhight {
  height: 68vh;
}
</style>
