<template>
  <v-container fluid>
    <v-row>
      <div
        style="
          width: 90%;
          margin: 0 auto;
          border-radius: 10px;
          background-color: rgb(238, 85, 85);
          color: white;
        "
        v-if="errorMessage != undefined"
      >
        <h3 style="width: 90%; margin: 0 auto">{{ errorMessage }}</h3>
      </div>

      <v-data-table
        :headers="[{ text: 'Feature', value: 'name' }]"
        :items="features"
        :expanded.sync="expanded"
        item-key="name"
        show-expand
        style="width: 100%"
      >
        <template v-slot:expanded-item="{ item }">
          <v-container class="mx-2">
            <v-row>
              <v-col v-for="company in companies" :key="company.companyId">
                <v-checkbox
                  v-if="
                    featuresWithCompany[item.featureId] !== undefined &&
                    featuresWithCompany[item.featureId][company.companyId] !==
                      undefined
                  "
                  :label="company.name ?? company.companyId"
                  v-model="
                    featuresWithCompany[item.featureId][company.companyId]
                  "
                  @click="toggleFeature(item.featureId, company.companyId)"
                />
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import FeatureAccessRepository from "@/api/repositories/featureAccessRepository";

export default {
  name: "FeatureAccessList",
  data() {
    return { errorMessage: undefined, expanded: [], featuresWithCompany: [] };
  },

  computed: {
    ...mapState("featureAccess", ["features"]),
    ...mapState("companies", ["companies"]),
  },

  methods: {
    ...mapActions("companies", ["getAll"]),
    ...mapActions("featureAccess", ["getAllFeatures"]),

    async toggleFeature(featureId, companyId) {
      let result = await FeatureAccessRepository.toggleFeature(
        featureId,
        companyId
      )
        .then(() => true)
        .catch(() => false);
      if (!result) this.errorMessage = "Could not update company feature";
      else this.errorMessage = undefined;
    },

    companyHaveFeature(featureId, companyId) {
      let feature = this.features.find((d) => d.featureId == featureId);
      if (feature === undefined) return false;

      if (
        feature.companies.find((d) => d.companyId === companyId) === undefined
      )
        return false;

      return true;
    },
  },

  async created() {
    await this.getAllFeatures();
    await this.getAll();

    for (let feature of this.features) {
      for (let company of this.companies) {
        if (this.featuresWithCompany[feature.featureId] === undefined)
          this.featuresWithCompany[feature.featureId] = [];
        if (
          this.featuresWithCompany[feature.featureId][company.companyId] ===
          undefined
        )
          this.featuresWithCompany[feature.featureId][
            company.companyId
          ] = false;
        this.featuresWithCompany[feature.featureId][company.companyId] =
          feature.companies.find((d) => d.companyId === company.companyId) !==
          undefined;
      }
    }
  },
};
</script>
