var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[(_vm.errorMessage != undefined)?_c('div',{staticStyle:{"width":"90%","margin":"0 auto","border-radius":"10px","background-color":"rgb(238, 85, 85)","color":"white"}},[_c('h3',{staticStyle:{"width":"90%","margin":"0 auto"}},[_vm._v(_vm._s(_vm.errorMessage))])]):_vm._e(),_c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"headers":[{ text: 'Feature', value: 'name' }],"items":_vm.features,"expanded":_vm.expanded,"item-key":"name","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function({ item }){return [_c('v-container',{staticClass:"mx-2"},[_c('v-row',_vm._l((_vm.companies),function(company){return _c('v-col',{key:company.companyId},[(
                  _vm.featuresWithCompany[item.featureId] !== undefined &&
                  _vm.featuresWithCompany[item.featureId][company.companyId] !==
                    undefined
                )?_c('v-checkbox',{attrs:{"label":company.name ?? company.companyId},on:{"click":function($event){return _vm.toggleFeature(item.featureId, company.companyId)}},model:{value:(
                  _vm.featuresWithCompany[item.featureId][company.companyId]
                ),callback:function ($$v) {_vm.$set(_vm.featuresWithCompany[item.featureId], company.companyId, $$v)},expression:"\n                  featuresWithCompany[item.featureId][company.companyId]\n                "}}):_vm._e()],1)}),1)],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }